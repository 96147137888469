<template>
<!-- 现货详情信息 -->
  <div id="page">
    <div class="nav">
      <HeaderBase :sear="'hide'"/>
    </div>

    <div class="main">
      <div class="header">
        <div class="left_img">
          <img :src="thisimgurl" alt="" class="detailimg">
          <div class="carousel_div">
            <button @click="carDivBtnNextLast('last')"
            class="car_left_button is_carbutton"><el-icon><arrow-left /></el-icon></button>
              <ul class="car_img_ul">
                <li class="car_img_li"
                v-for="(i,index) in this.product.albumPics" :key="index">
                  <span class="li_span_img"
                  :class="isactCarimg==index?'isact_carimg':''"
                  @click="isact(index,'isactCarimg')"><img :src="i" alt=""></span>
                </li>
              </ul>
            <button @click="carDivBtnNextLast"
            class="car_right_button is_carbutton"><el-icon><arrow-right /></el-icon></button>
          </div>
        </div>
        <div class="center_car">
          <div class="center_title">
            <span class="yushouCont">{{$t('m.cashcommodity.spot_goods')}}</span>
            <h4 class="titlefont">{{product.name}}</h4>
          </div>
          <el-form ref="datalist" :model="datalist" label-width="500px">
            <el-form-item :label="$t('m.reservation_varietiy_details.Price')" label-width="100px">
              <span class="saleclass">
                {{product.currencySymbol}}<NologindataVue :price='thisStock.price' />{{'/'+product.unit}}
              </span>
            </el-form-item>
            <div class="itemdiv">
              <span class="itemdiv_span">
                {{$t('m.reservation_varietiy_details.Stock')}}
                <strong>{{thisStock.stock + product.unit}}</strong>
              </span>
            </div>
            <el-form-item :label="$t('m.reservation_varietiy_details.Minimum_Order_Quantity')+':'" :label-width="$t('m.reservation_varietiy_details.Delivery_width')">
              <div class="dinghuo"><i class=""></i>{{product.minBuy + product.unit}}</div>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.Minimum_Increment')+':'" :label-width="$t('m.reservation_varietiy_details.Delivery_width')">
              <div class="dinghuo"><i class=""></i>{{product.minIncr + product.unit}}</div>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.Validity_of_Quotation')" :label-width="$t('m.reservation_varietiy_details.Delivery_width')">
              <span>{{product.offerExpiryDate}}</span>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.Selective_specification')" :label-width="$t('m.reservation_varietiy_details.Delivery_width')">
              <div class="guige">
                <el-tooltip placement="top" effect="light"
                  v-for="(i,index) in skuStockList" :key="index"
                  :class="isactive==index?'isactive':''">
                  <template #content>
                    <div style="display:flex; align-items: center;">
                      <img :src="i.pic" style="width:80px;height:80px;">
                      <div>
                        <p style="margin-left:10px;color:#999999;line-height: 30px;">
                          {{$t('m.reservation_varietiy_details.guigeName')}}:
                          <span style="color:#333333;">
                            {{i.spData[0].value}}</span>
                        </p>
                        <p style="margin-left:10px;color:#999999;line-height: 20px;">
                          {{$t('m.reservation_varietiy_details.guigeStock')}}：<span style="color:#333333;">
                            {{i.stock+product.unit}}</span>
                        </p>
                        <p style="margin-left:10px;color:#999999;line-height: 30px;">
                          {{$t('m.reservation_varietiy_details.guigePrice')}}：<span style="color:#333333;">
                            {{product.currencySymbol}}{{islogin ? i.price : $t('m.index.Login_to_view_prices')}}{{'/'+product.unit}}</span>
                        </p>
                        <!-- <p style="margin-left:10px;color:#999999;line-height: 20px;">
                          提货日期：<span style="color:#333333;">
                            {{i.spData[0].value}}</span>
                        </p> -->
                      </div>
                    </div>
                  </template>
                  <span class="btn" @click="isact(index, 'isactive', i)"
                  v-show="index<=3?true:showallgg"
                  >
                    {{i.spData[0].value}}
                  </span>
                </el-tooltip>
              </div>
              <span class="showallsp" v-if="skuStockList.length>4"
                @click="this.showallgg=!showallgg">
                  <el-icon>
                    <component :is="showallgg?'ArrowUpBold':'ArrowDownBold'"></component>
                  </el-icon>
                </span>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.number')" label-width="100px">
              <el-input-number
                v-model="thisBuyNum"
                :step="product.minIncr"
                size="mini"
                :min="Number(product.minBuy)"
                :max="thisStock.stock"
                @change="changeNum(thisBuyNum)"/>
            </el-form-item>
          </el-form>
          <span class="btnspan">
            <div class="btn_div">
              <button class="gobutton gm" :style="`left:${$t('m.reservation_varietiy_details.attentionLeft')}`"
                  @click="toBuyPage">
                  {{$t('m.reservation_varietiy_details.purchase')}}
              </button>
              <button class="gobutton gz" :style="`left:${$t('m.reservation_varietiy_details.purchaseLeft')}`"
                  @click="likeVarietiy">
                  {{$t('m.reservation_varietiy_details.attention')}}
              </button>
            </div>
          </span>
        </div>
        <div class="right_test">
          <h3 class="righttitle">{{$t('m.reservation_varietiy_details.Estimated_price')}}</h3>
          <el-form class="right_form"
          label-suffix=":"
          ref="datalist" label-width="259px">
            <el-form-item :label="$t('m.reservation_varietiy_details.est_select_specifications')" label-width="80px">
              <el-select
                size="mini"
                v-model="thisRightTest.guigevalue"
                :placeholder="$t('m.reservation_varietiy_details.Please_select_specifications')">
                  <el-option
                    v-for="(i,k) in skuStockList" :key="k"
                    :label="i.spData[0].value"
                    :value="i.price">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('m.reservation_varietiy_details.Purchased_weight')" label-width="80px">
              <el-input-number
                v-model="thisRightTest.weightvalue"
                :step="product.minIncr"
                size="small"
                :min="product.minBuy>thisStock.stock?thisStock.stock:product.minBuy"
                :max="thisStock.stock"/>
            </el-form-item>
          </el-form>
          <div class="heji">
            {{$t('m.reservation_varietiy_details.Estimated_total_payment')}}：<span>{{product.currencySymbol}}
              {{Number(allPrice).toFixed(1)}}</span>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="supplier_message">
          <h3>{{$t('m.reservation_varietiy_details.Supplier_file')}}</h3>
          <p class="supname">{{companyInfo.companyName}}</p>
          <!-- <span class="supgood">好评率：{{(99.5561).toFixed(2)}}%</span> -->
          <div class="supxiangxi">
            <div class="suplogo">
              <img :src="companyInfo.logo" alt="">
            </div>
            <div class="xxdiv">
              <el-icon><location /></el-icon>
              <span>{{companyInfo.address}}</span></div>
            <div class="xxdiv">
              <el-icon><phone-filled /></el-icon>
              {{companyInfo.phoneNumber}}</div>
            <div class="xxdiv">
              <el-icon><avatar /></el-icon>
              <el-popconfirm
                confirm-button-text="是的"
                cancel-button-text="再考虑一下"
                icon="InfoFilled"
                icon-color="#626AEF"
                :title="product.focusOnMerchant==1?'你要取关该供应商吗?':'你要关注该供应商吗?'"
                @confirm="confirmEvent"
                @cancel="cancelEvent"
              >
                <template #reference>
                  <el-button size="mini">{{product.focusOnMerchant==1?$t('m.reservation_varietiy_details.followed'):$t('m.reservation_varietiy_details.attention')}}</el-button>
                </template>
              </el-popconfirm>
            </div>
            <div class="xxdiv" v-if="false">
              <el-icon><ChatDotRound /></el-icon>
              <!-- <el-button size="mini" @click="goToMessage()">联系</el-button> -->
              <CreateChat :productData='{
                productInfo: this.productInfo,
                merchantId: this.product.merchantId,
                productId:this.product.id,
                companyName:this.companyInfo.companyName
              }' />
            </div>
          </div>
        </div>

        <div class="supplier_commodity">
          <div class="sample_div" v-if="product.productAttributeCategoryId == 1">
            <el-button type="warning" size="small" @click="toSampleBuy()">申请样品</el-button>
          </div>
          <!-- <h2>{{$t('m.reservation_varietiy_details.Coffee_details')}}</h2> -->
          <h2>{{$t('m.reservation_varietiy_details.commodity_details')}}</h2>
          <div class="coffee_mes">
            <ul>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.Product_number')}}：</h4>
                <span class="li_mmsg">{{product.productSn}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.way_of_quoting')}}：</h4>
                <span class="li_mmsg">{{product.offerForm}}</span>
              </li>
              <li class="coffee_li" v-for="i in productAttrListValue" :key="i"
                :class="
                i.name=='庄园介绍'||
                i.name=='咖啡风味'?'coli1':''">
                <h4>{{i.name}}：</h4>
                <span class="li_mmsg">{{i.value}}</span>
              </li>
              <!-- <li class="coffee_li">
                <h4>{{$t('m.merchantworkbench.authentication')}}：</h4>
                <span class="li_mmsg">{{product.authentication}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.farm')}}：</h4>
                <span class="li_mmsg">{{product.manorName}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.place_of_origin')}}：</h4>
                <span class="li_mmsg">{{product.originPlace}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.treeSpecies')}}：</h4>
                <span class="li_mmsg">{{product.treeSpecies}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.year')}}：</h4>
                <span class="li_mmsg">{{product.productTime}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.elevation')}}：</h4>
                <span class="li_mmsg">{{product.altitude + '米'}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.Picking_method')}}：</h4>
                <span class="li_mmsg">{{product.pickWay}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.merchantworkbench.cuppingReference')}}：</h4>
                <span class="li_mmsg">{{product.cuppingReference}}</span>
              </li>
              <li class="coffee_li">
                <h4>{{$t('m.reservation_varietiy_details.way_of_quoting')}}：</h4>
                <span class="li_mmsg">{{product.offerForm}}</span>
              </li>
              <li class="coffee_li coli1">
                <h4>{{$t('m.reservation_varietiy_details.processing_method')}}：</h4>
                <span class="li_mmsg">{{product.processWay}}</span>
              </li>
              <li class="coffee_li coli1">
                <h4>{{$t('m.merchantworkbench.Manor_introduction')}}：</h4>
                <span class="li_mmsg">{{product.introductionToTheEstate}}</span>
              </li>
              <li class="coffee_li coli1">
                <h4>{{$t('m.reservation_varietiy_details.Coffee_flavor')}}：</h4>
                <span class="li_mmsg">{{product.flavorDescription}}</span>
              </li> -->
            </ul>
          </div>
          <div class="commodity_message">
            <el-breadcrumb separator="|">
              <el-breadcrumb-item
              v-for="i in commMess" :key="i">
                <div class="com_body">
                  <span>{{i.cont}}</span>
                  <p>{{i.title}}</p>
                </div>
              </el-breadcrumb-item>
              <!-- <el-breadcrumb-item>promotion management</el-breadcrumb-item> -->
            </el-breadcrumb>
          </div>
          <!-- <h2>{{$t('m.reservation_varietiy_details.commodity_details')}}</h2> -->
          <div class="commodity_content">
            <p class="cont_message">
              {{product.detailDesc}}
            </p>
            <div class="cont_img" v-html="product.detailHtml"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="divfooter">
        <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from '../index/Footer.vue'
import HeaderBase from '../../components/HeaderBase.vue'
import NologindataVue from '../../components/Nologindata.vue'

import { getStore, setStore } from '../../tools/storage.js'

// import { toRaw } from 'vue'
import { reactive, ref } from 'vue-demi'
import http from '../../api/http.js'
import CreateChat from '../../components/chatroom/createChat.vue'
import { ElMessage } from 'element-plus'
// import moment from 'moment'
// import i18n from '@/tools/i18n.js'
// const { t } = i18n.global
// 关注商品接口
const followTheProduct = (data) => {
  return http.post('/mall-portal/mall/pmsfollowtheproduct/followTheProduct', data)
}
// 关注店铺接口
const followTheStore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/followTheStore', data)
}
// 取消关注店铺
const unpmsfollowthestore = (data) => {
  return http.post('/mall-portal/mall/pmsfollowthestore/deleteByMerchantId', data)
}
// const headernav = [
//   { text: t('m.Header.home_page'), path: '/' },
//   { text: t('m.search.login'), path: '/loginandregister' },
//   { text: t('m.Header.mobile_terminal'), path: '' }
// ]
export default {
  name: 'VarietiyDetails',
  data () {
    return {
      islogin: '', // 是否登录
      endTime: '',
      previewStatus: 0,
      isactive: ref(0),
      isactCarimg: ref(0),
      thisimgurl: '', // 选择的图片
      thisStock: '?', // 选择的规格（库存）
      thisBeginDay: 0, // 预提提前开始几天
      thisEndDay: 0, // 预提提前开始几天
      thisBuyNum: ref(0), // 购买数量

      atime: [],
      thisRightTest: reactive({
        guigevalue: '',
        weightvalue: 0,
        fukuanvalue: ''
      }), // 右侧预估价格
      yunfei: 0,
      allPrice: ref(0), // 预估合计
      commMess: [
        { title: this.$t('m.reservation_varietiy_details.brand'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.date_issued'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.storage_life'), cont: '12个月' },
        { title: this.$t('m.reservation_varietiy_details.place_of_dispatch'), cont: '' },
        { title: this.$t('m.reservation_varietiy_details.delivery_points'), cont: '' }
      ], // 详情头部信息
      datalist: reactive({}),
      guigevalue: '',
      showallgg: ref(false),
      timeTags: reactive([]), // 所选日期
      showallsp: ref(false),
      tihuoValue: '', // 默认显示时间
      // comList: reactive({})
      brand: '',
      companyInfo: '',
      couponList: [],
      pmsProductContractList: [],
      pmsProductPreTakeWayList: [],
      pmsSiteEntityHashMap: [],
      pmsSiteEntity: '',
      product: '',
      productAttributeList: [], // 详情名称
      productAttributeValueList: [], // 详情参数
      productAttrListValue: [], // 处理后的详情数据
      productFullReductionList: [],
      productLadderList: [],
      productPresaleExtraList: [],
      redPacketList: [],
      skuStockList: [],
      newSkuStockList: [], // 处理后展示的规格
      newSkuAllList: [], // 同一规格不同时间展示的数据

      // 聊天窗口信息
      productInfo: {}
    }
  },
  components: { HeaderBase, Footer, NologindataVue, CreateChat },
  watch: {
    'thisRightTest.guigevalue' (v, oldv) {
      this.allPrice = (v * this.thisRightTest.weightvalue)
    },
    'thisRightTest.weightvalue' (v, oldv) {
      this.allPrice = (v * this.thisRightTest.guigevalue)
    }
  },
  methods: {
    isact (k, name, item) {
      switch (name) {
        case 'isactCarimg':
          this.isactCarimg = k
          this.thisimgurl = this.product.albumPics[k]
          break
        case 'isactive':
          this.isactive = k
          this.tihuoValue = ''
          this.thisBuyNum = this.product.minBuy // 切换规格，数量变成最小订货量
          console.log(item)
          this.thisStock = item // 选择的规格参数
          this.endTime = item.spData[0].value // 日期更改 存规格名称
          console.log(this.thisStock)
          break
        default:
          break
      }
    },
    carDivBtnNextLast (sta) {
      if (sta === 'last') {
        this.isactCarimg === 0 ? this.isactCarimg = this.product.albumPics.length - 1 : this.isactCarimg--
        this.thisimgurl = this.product.albumPics[this.isactCarimg]
      } else {
        this.isactCarimg === this.product.albumPics.length - 1 ? this.isactCarimg = 0 : this.isactCarimg++
        this.thisimgurl = this.product.albumPics[this.isactCarimg]
      }
    },
    toSampleBuy () { // 申请样品
      const loading = this.$loading({
        lock: true,
        text: '加载中'
      })
      if (!this.islogin) {
        ElMessage.warning({
          message: '请登录后申请！'
        })
        this.$router.push('/loginandregister')
        loading.close()
        return
      }
      if (!this.product.sampleNumber) {
        ElMessage.warning({
          message: '该商品没有样品，请联系商家补充！'
        })
        loading.close()
        return
      }
      // setTimeout(() => {
      setStore('sampleordermessage', JSON.stringify(this.comList))
      // console.log(typeof data[0].resaleFlag)
      this.$router.push({
        path: '/samplebuy',
        query: {
          name: this.product.name,
          sampleNumber: this.product.sampleNumber,
          previewStatus: '0' // 现货
          // time: encodeURIComponent(JSON.stringify(toRaw(this.timeTags)))
          // prodata: encodeURIComponent(JSON.stringify(res.data.data))
        }
      })
      // window.open(this.$router.href)
      loading.close()
      // }, 1000)
    },
    toBuyPage () { // 去购买
      const data = [{
        // resaleProductId: this.product.id,
        // resaleFlag: 1, // 是否可以转售
        SkuId: this.thisStock.id,
        SkuCode: this.thisStock.skuCode,
        number: this.thisBuyNum
      }]

      console.log(data)
      console.log(this.skuStockList)
      if (this.timeTags.length > 1) {
        for (const i of this.timeTags) {
          console.log(i)
        }
      }

      http.post('/mall-order/order/datePresaleGenerateConfirmOrder', data, true)
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            try {
              const loading = this.$loading({
                lock: true,
                text: '加载中'
              })
              setTimeout(() => {
                setStore('confirmordermessage', JSON.stringify(res.data.data))
                // console.log(typeof data[0].resaleFlag)
                this.$router.push({
                  path: '/buy',
                  query: {
                    name: this.product.name,
                    产地: this.product.originPlace,
                    previewStatus: res.data.data.pmsProduct.previewStatus
                    // time: encodeURIComponent(JSON.stringify(toRaw(this.timeTags)))
                    // prodata: encodeURIComponent(JSON.stringify(res.data.data))
                  }
                })
                // window.open(this.$router.href)
                loading.close()
              }, 1000)
            } catch (error) {
              console.log(error)
            }
          } else {
            ElMessage.error({ message: res.data.msg })
          }
        }).catch(err => {
          console.log(err)
        })
    },
    changeNum (sum) {
      // console.log(sum)
      // const maxStock = this.thisStock.stock
      const minBuy = this.product.minBuy
      const minIncr = this.product.minIncr
      const zengGou = sum - minBuy
      // if (sum >= maxStock - minBuy) {
      //   this.thisBuyNum = maxStock
      //   // console.log(this.thisBuyNum)
      //   return
      // }
      if (zengGou >= minIncr) {
        console.log(Math.ceil(zengGou / minIncr))
        this.thisBuyNum = minBuy + Math.ceil(zengGou / minIncr) * minIncr
        // if (maxStock - this.thisBuyNum < minBuy) {
        //   this.thisBuyNum = maxStock
        // } else {
        //   // this.thisBuyNum = maxStock - minBuy
        // }
      } else {
        this.thisBuyNum = minIncr
      }
      console.log(this.thisBuyNum)
    },
    async likeVarietiy () { // 关注商品
      const data = {
        previewStatus: 0,
        productId: null,
        productSn: this.$route.query.productSn
      }
      const res = await followTheProduct(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    async confirmEvent () { // 关注供应商
      // console.log('confirm!')
      const data = {
        merchantId: this.companyInfo.memberId
      }
      if (this.product.focusOnMerchant === 1) {
        const res = await unpmsfollowthestore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '取关成功'
          })
          this.product.focusOnMerchant = 0
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      } else {
        const res = await followTheStore(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: '关注成功!'
          })
          this.product.focusOnMerchant = 1
        } else {
          ElMessage.error({
            message: res.data.msg
          })
        }
      }
    },
    cancelEvent () {
      // 取消功能
    }
  },
  mounted () {
    // console.log(this.$route.query)
    window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部

    const loading = this.$loading({
      lock: true,
      text: '查询中...'
    })
    // const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
    const userId = (getStore('usermessage') && getStore('usermessage').substring(0, 1) === '{') ? JSON.parse(getStore('usermessage')) : null
    this.islogin = userId
    // console.log(userId)
    const data = {
      productSn: this.$route.query.productSn,
      userId: userId ? userId.id : null
    }
    http.get('/mall-portal/product/detailBySn', data)
      .then(res => {
        console.log(res.data.data)
        this.comList = res.data.data
        // ========
        this.brand = this.comList.brand
        this.companyInfo = this.comList.companyInfo
        this.couponList = this.comList.couponList
        this.pmsProductContractList = this.comList.pmsProductContractList
        this.pmsProductPreTakeWayList = this.comList.pmsProductPreTakeWayList
        this.pmsSiteEntityHashMap = this.comList.pmsSiteEntityHashMap
        this.pmsSiteEntity = this.comList.pmsSiteEntity
        this.product = this.comList.product
        this.productInfo = this.comList.product
        this.productAttributeList = this.comList.productAttributeList
        this.productAttributeValueList = this.comList.productAttributeValueList
        this.productFullReductionList = this.comList.productFullReductionList
        this.productLadderList = this.comList.productLadderList
        this.productPresaleExtraList = this.comList.productPresaleExtraList
        this.redPacketList = this.comList.redPacketList
        this.skuStockList = this.comList.skuStockList

        // console.log(this.product.albumPics)
        // 赋值给原数据
        this.product.albumPics = this.comList.product.albumPics.split(',') // 轮播图
        this.thisimgurl = this.product.albumPics[0]
        this.thisBeginDay = this.productPresaleExtraList[0].advancePikegoodsDaysBegin
        this.thisEndDay = this.productPresaleExtraList[0].advancePikegoodsDaysEnd

        this.commMess[0].cont = this.product.brandName
        this.commMess[1].cont = this.product.createTime
        // this.commMess[2].cont = this.product.description
        this.commMess[3].cont = this.pmsSiteEntity.name
        this.commMess[4].cont = this.pmsSiteEntity.name

        this.thisBuyNum = this.product.minBuy // 最少购买数量
        this.thisRightTest.fukuanvalue = this.product.prePayRate // 预估付款比例

        this.previewStatus = this.product.previewStatus // 是否是预售 0->不是；1->是

        for (const i of this.productAttributeValueList) { // 处理详情数据
          for (const j of this.productAttributeList) {
            if (i.productAttributeId === j.id) {
              this.productAttrListValue.push({
                name: j.name,
                value: i.value
              })
            }
          }
        }

        for (const i of this.skuStockList) {
          i.spData = JSON.parse(i.spData) // 给规格转换格式
        }
        this.thisStock = this.skuStockList[0]
        // console.log(this.thisStock)
        loading.close()
      }).catch(err => {
        console.log(err)
        // console.log(calcuTime(this.previewStatus, this.endTime, this.thisBeginDay, this.thisEndDay))
      })
  },
  setup (props, ctx) {
    // const datacop = getCurrentInstance()
    // calcuTime(
    //   datacop.product.previewStatus,
    //   datacop.product.offerExpiryDat,
    //   datacop.thisBeginDay,
    //   datacop.thisEndDay
    // )
    // console.log(calcuTime(1, '2022-1-25', 2, 10))
    return {
      data: ref('')
    }
  }
}
</script>

<style lang="scss">
@import '../../style/viriables.scss';

#page{
  background: #F2EFED;
  width: 100%;
  // height: 100%;
  .nav{
    // height: 30px;
    width: 100%;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            cursor: pointer;
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .main{
    width: 1200px;
    margin: 0 auto;
    font-family: Microsoft YaHei;
    font-weight: 400;
    .header{
      margin-top: 10px;
      width: 1200px;
      // height: 400px;
      background: #FFFFFF;
      display: flex;
      .left_img{
        padding: 20px;
        border-right: 1px solid #f2f2f2;
        .detailimg{
          height: 360px;
          width: 360px;
        }
        .carousel_div{
          height: 58px;
          width: 100%;
          display: flex;
          align-items: center;
          button{
            width: 30px;
            height: 54px;
            background: #f2f2f2;
            // opacity: 0;
            transition: all 0.3s;
          }
          // button
          .car_img_ul{
            // flex: 1;
            width: 300px;
            display: flex;
            // overflow: auto;
            // justify-content: space-between;
            .car_img_li{
              flex: 1;
              .li_span_img{
                display: flex;
                // flex-shrink: 0;
                justify-content: center;
                align-items: center;
                img{
                  border: 2px solid transparent;
                  width: 90%;
                  max-width: 50px;
                  min-width: 33px;
                  min-height: 33px;
                  max-height: 50px;
                  height: 90%;
                  transition: all 0.3s;
                }
              }
              .isact_carimg{
                img{
                  border-color: #C6824E;
                }
              }
            }
          }
        }
        .carousel_div:hover{
          button{
            // opacity: 1;
            background: #EAEAEA;
          }
        }
        .el-carousel{
          .el-carousel__container{
            .el-carousel__item{
              // width: 60px;
              img{
                display: block;
                margin: 0 auto;
                width: 58px;
                height: 58px;
              }
            }
          }
        }
      }
      .center_car{
        flex: 1;
        display: flex;
        flex-flow: column;
        padding: 20px;
        .center_title{
          display: block;
          .yushouCont{
            float: left;
            min-width: 30px;
            padding: 0 5px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
            border-radius: 4px;
            margin-right: 5px;
          }
          .titlefont{
            font-size: 18px;
            color: #333333;
          }
        }
        .el-form{
          padding: 0;
          :first-child.el-form-item{
            margin: 20px 0;
          }
          .el-form-item{
            padding: 0 20px;
            margin-bottom: 0;
            // height: 40px;
            .el-form-item__label{
              text-align-last: justify;
              text-align: left;
              color: #999999;
            }
            .el-form-item__content{
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .saleclass{
                font-size: 20px;
                font-weight: bold;
                color: #C6824E;
              }
              .stock_num{
                color: #333333;
              }
              .dinghuo{
                // margin-right: 50px;
                i{
                  color: #666666;
                  // margin-right: 30px;
                }
              }
              .guige{
                display: flex;
                flex-wrap: wrap;
                .btn{
                  display: block;
                  float: left;
                  text-align: center;
                  height: 30px;
                  width: 75px;
                  border: 1px solid #EAEAEA;
                  margin-right: 8px;
                  margin-bottom: 8px;
                  border-radius: 2px;
                  line-height: 30px;
                  background: transparent;
                  box-shadow: none;
                  color: #333333;
                  cursor: pointer;
                  white-space: nowrap;
                  overflow: hidden;
                }
                .isactive{
                  border-color: #C6824E;
                  color: #C6824E;
                  background: url('../../static/imges/spanGg.png')no-repeat 62px 16px;
                }
              }
              .showallsp{
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
          .itemdiv{
            height: 30px;
            background: #FFEEE0;
            padding: 0 20px;
            .itemdiv_span{
              font-size: 12px;
              line-height: 30px;
              color: #C6824E;
              strong{
                font-size: 14px;
              }
            }
          }
          .tihuo_time{
            .el-form-item__content{
              align-items: flex-start;
              .block{
                // flex: 1;
                // background: #FFFFFF;
                border-radius: 4px;
                display: flex;
                .el-input--prefix{
                  width: 135px;
                  align-items: center;
                  input::-webkit-input-placeholder{
                    color: #999999;
                  }
                  .el-input__prefix-inner{
                    .el-icon{
                      color: #C6824E;
                    }
                  }
                }
              }
              .spanlist{
                display: flex;
                flex-wrap: wrap;
                .el-tag{
                  margin: 6px 10px 5px 0;
                }
              }
              .showallsp{
                position: absolute;
                right: -15px;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }
          .yuti{
            padding:0 20px;
            font-size: 12px;
            margin-bottom: 15px;
            span{
              color: #999999;
              margin-right: 40px;
              i{
                color: #333333;
              }
              .el-icon{
                top: 2px;
                color: #999999;
              }
            }
          }
        }
        .btnspan{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .btn_div{
            float: right;
            width: 40%;
            position: relative;
            height: 52px;
            background: url('../../static/imges/42559e2c11e8b82ba91e451581be42b.png') no-repeat;
            .gobutton{
              position: absolute;
              top: 10px;
              font-size: 14px;
              height: 30px;
              background: transparent;
              border-radius: 4px;
            }
            .gz{
              left: 33px;
              color: #673000;
            }
            .gm{
              left: 110px;
              color: #F2EFED;
            }
          }
        }
      }
      .right_test{
        width: 259px;
        .righttitle{
          height: 58px;
          background: #C6824E;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 58px;
          text-align: center;
        }
        .right_form{
          margin: 40px 0;
          .el-form-item{
            margin-bottom: 0;
            .el-form-item__label{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space:nowrap;
            }
            .el-form-item__content{
              .el-input--mini{
                width: 165px;
              }
            }
          }
        }
        .heji{
          text-align: right;
          padding: 0 15px;
          font-size: 14px;
          color: #333333;
          span{
            display: inline-block;
            font-size: 20px;
          }
        }
      }
    }
    .detail{
      margin-top: 10px;
      display: flex;
      .supplier_message{
        width: 240px;
        height: 530px;
        margin-bottom: 50px;
        background: #FFFFFF;
        border: 1px solid #C6824E;
        padding: 0 20px;
        display: flex;
        flex-flow: column;
        h3{
          text-align: center;
          padding: 20px;
          font-size: 20px;
          font-weight: bold;
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          border-bottom: 2px solid #2B1001;
        }
        .supname{
          margin-top: 18px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        .supgood{
          margin-top: 18px;
          font-size: 12px;
          color: #C6824E;
        }
        .supxiangxi{
          flex: 1;
          margin: 20px 0;
          .xxdiv{
            display: flex;
            align-items: center;
            color: #666666;
            margin-top: 18px;
            font-size: 14px;
            line-height: 21px;
            .el-icon{
              margin-right: 5px;
              // margin-top: 3px;
            }
            .el-button{
              width: 80px;
              border: 1px solid #EAEAEA;
              color: #666666;
            }
          }
          .suplogo{
            width: 100%;
            img{
              width: 200px;
              // height: 200px;
              border-radius: 50%;
            }
          }
        }
      }
      .supplier_commodity{
        background: #FFFFFF;
        flex: 1;
        margin-left: 10px;
        padding: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        .sample_div{
          display: flex;
          flex-direction: row-reverse;
        }
        h2{
          color: #333333;
          font-size: 20px;
          margin: 10px 0 20px;
        }
        .commodity_message{
          height: 90px;
          box-sizing: border-box;
          background: #f7f7f7;
          padding: 28px 20px;
          .el-breadcrumb{
            display: flex;
            .el-breadcrumb__item{
              flex: 1;
              display: flex;
              .el-breadcrumb__inner{
                flex: 1;
                .com_body{
                  span{
                    display: block;
                    margin-bottom: 12px;
                    color: #333333;
                  }
                  p{
                    color: #999999;
                  }
                }
              }
              .el-breadcrumb__separator{
                margin-right: 15px;
                line-height: 40px;
              }
            }
          }
        }
        .commodity_content{
          .cont_message{
            font-size: 14px;
            color: #666666;
            line-height: 21px;
            margin-bottom: 20px;
          }
          .cont_img{
            max-width: 867px;
            overflow:visible;
            overflow: auto;
          }
        }
        .coffee_mes{
          ul{
            display: flex;
            flex-flow: wrap;
            padding: 0 20px;
            .coffee_li{
              min-height: 50px;
              width: 50%;
              display: flex;
              h4{
                line-height: 30px;
                white-space: nowrap;
              }
              span{
                line-height: 30px;
                color: #666666;
                word-break: break-all;
              }
            }
            .coli1{
              width: 100%;
            }
          }
        }
      }
    }
  }
 .divfooter{
    background: #280E01;
  }
}
</style>
